import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { lehLadakhTags } from '../../../Componets/Images'
import { RecoHotel, RecommendedVehicle, RecoPkg } from '../../../Componets/Recommended'
import { dateFormat } from '../../../Componets/CommonFs'
import { HTMLRender, HTMLRenderNormal } from '../../../Componets/HTMLRender'
import { dec, enc } from '../../../Componets/SecRes'
import { CommonAPI } from '../../../Componets/CommonAPI'
import { Spin } from 'antd'
import { Loader } from '../../../Componets/Loader'
import Carousel from 'react-multi-carousel'
import { CustomLeftArrow } from '../../../Componets/CustomArrow'
import { useLocalStorage } from 'react-fu-hooks'

const responsive_pkg = {
  xdesktop1: {
    breakpoint: { max: 2560, min: 2000 },
    items: 11,
    slidesToSlide: 8
  },
  desktop1: {
    breakpoint: { max: 2000, min: 1200 },
    items: 9,
    slidesToSlide: 7
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 7,
    slidesToSlide: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 788 },
    items: 6,
    slidesToSlide: 5
  },
  mobile: {
    breakpoint: { max: 787, min: 559 },
    items: 4,
    slidesToSlide: 3
  },
  xmobile: {
    breakpoint: { max: 559, min: 449 },
    items: 3,
    slidesToSlide: 2
  },
  xsmobile: {
    breakpoint: { max: 449, min: 0 },
    items: 3,
    slidesToSlide: 2
  },
  xxsmobile: {
    breakpoint: { max: 380, min: 0 },
    items: 2,
    slidesToSlide: 1
  },
};

const img_base_url = process.env.REACT_APP_IMG_BASE_URL;
const key = process.env.REACT_APP_KEY;

function BlogDetails() {
  const { slug, id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const [showTags, setShowTags] = useState(30);
  const [showTags2, setShowTags2] = useState(30);
  const [showTags3, setShowTags3] = useState(30);

  const [tagsList, setTagsList] = useState([]);
  const [data, setData] = useState([]);
  const [recent, setRecent] = useState([]);
  const [hotelType, setHotelType] = useState([]);
  const [cate, setCate] = useState([]);
  const [hotelId, setHotelId] = useLocalStorage('hotelType', '')
  const [hotelLocation, setHotelLocation] = useLocalStorage('hotelLocation', '')
  // const [data, setData] = useState([]);
  // const [data, setData] = useState([]);

  const [isLoading1, setIsLoading1] = useState(false);


  useEffect(() => {
    getDetails();
  }, [slug]);


  const getDetails = (tagId) => {
    const data = {
      id: Number(id),
      slug: slug
    }
    const enc1 = enc(data, key)


    setIsLoading1(true)
    CommonAPI(enc1, 'POST', 'blogDetails', responseBack)
  }

  const responseBack = useCallback(res => {

    if (res.status === true) {
      const dec1 = dec(res?.data, key);
      setData(dec1?.blog)
      setRecent(dec1?.recent)
      setHotelType(dec1?.hotelType)
      setCate(dec1?.category)

      const country = Object.values(dec1?.blog[0]?.countryData)

      setTagsList([])
      CommonAPI({}, 'GET', `topTagsList?type=${country.length > 0 ? 'country' : ''}&typeId=${country.length > 0 ? country[1] : ''}`, responseBack2)
    } else {
      // navigate('/not-found', { replace: true });
    }
    setIsLoading1(false)
  })

  const responseBack2 = useCallback(res => {
    if (res.status === true) {
      const dec1 = dec(res?.data, key)

      setTagsList(dec1)
    }
  })

  const dt = dateFormat(data[0]?.createdAt, 'date')


  const openAbout = (name, id, idLong, type) => {
    const slug = name.replace(/ /g, '-')

    navigate(`/${type}/${slug}/${id}`, { state: { id: idLong, type: type } })
  }

  const handleCallback = async (value, type) => {

    if (type === 'categoryId') {
      const update = { cID: data[0]?.countryData?._id || '66a1e7b2e3e1ced4992a2617', month: [], location: '', categoryId: [value], cName: 'India' }
      localStorage.setItem('searchParams', JSON.stringify(update));

      const pkgHome = localStorage.getItem('searchParams');
      const newState = JSON.parse(pkgHome)

      localStorage.setItem('searchParams', JSON.stringify(newState));

      navigate('/package/list')
    }
  }


  const handleSave = (value) => {
    setHotelId(value);
    window.open(`/hotels`, '_blank')
  };


  const filterdRecent = recent.filter(item => item.id !== Number(id));


  return (
    <div className='d-flex flex-column align-items-center mt-12 pt-4 p-4 bg-gray'>
      <div className='w_95 mt-4 mb-4 bg-success1'>

        <Row className='bg-warning1 p-0'>
          {isLoading1 && (
            <Spin size='default' className='mb-15px' />
          )}
          <Col md={7} lg={8} className='p-0 mb-md-4'>
            <div className='bg-white p-3'>
              {data && data?.length > 0 &&
                <>
                  <img src={`${img_base_url + data[0]?.image}`} className='img-fluid rd_6 h_380px h-xxs-150px h-xs-250px w-100 position-relative overflow-hidden' />
                  <h3 className='Rubik-SemiBold text-capitalize text_gray fs_18 m-0 mt-3'>{data[0]?.title}</h3>
                  <p className='Source_Medium fs_12 text-primary m-0 mt-1'>By {data[0]?.writer}, {dt}</p>

                  <p className='Rubik-Regular fs_14 m-0 mt-3'>
                    <HTMLRender htmlContent={data[0]?.description} />
                  </p>
                </>
              }

            </div>
          </Col>

          <Col md={5} lg={4} className=''>
            {recent && recent?.length > 0 &&
              <h2 className="Source_Bold fs_15 mt-2 mb-3">Recent Blogs</h2>
            }
            {recent && filterdRecent?.slice(0, 5).map((it, index) => {
              const dt = dateFormat(it?.createdAt, 'date')


              return (
                <Link key={index} to={`/blog/${it.slug}/${it.id}`} state={{ id: it._id }} className='text-decoration-none rd_4 p-2 bg-white hover-me d-flex align-items-start mb-3' onClick={() => { setRecent([]); setTagsList([]); setData([]) }}>
                  <div className='flex-grow-1'>
                    <img src={`${img_base_url + it.image}`} className='img-fluid h_90px rd_4 w_200px' />
                  </div>
                  <div className='bg-primary1 ms-2 w-100'>
                    <h3 className='Rubik-Medium text-capitalize text_gray fs_13 m-0 fs-xs-14 mini-line-1'>{it.title}</h3>
                    <p className='Rubik-Regular fs_12 text-secondary m-0 mini-line-1 two'>{it.sortDescription}</p>
                    <p className='Source_Medium fs_12 text-primary m-0 mt-2'>By {it.writer || 'Anonymous'}, {dt}</p>
                  </div>
                </Link>
              )
            })}

            <div className='rd_6 d-flex align-items-center flex-column bg-white p-3 px-4'>
              <img src='/images/man_with_lauggage.png' className='img-fluid h_130px w_200px rotate-img-flip' />
              <p className='m-0 fw-bold fs_16 mt-4'>Couldn't find</p>
              <p className='m-0 Rubik-Regular text-secondary fs_13 mb-4 text-center mt-1'>Tired of searching? Simply submit your travel details and receive quotations from multiple verified vendors. Choose suitable quotation from the list and enjoy your journey.</p>

              <Link to={'/post-travel'}>
                <button className='bg-gradient-thm btn w-auto mt-3 d-flex flex-grow-1 justify-content-center align-items-center rd_6 text-white pointer h_40px fs_14 Source_Medium px-4'>
                  Post Your Travel
                </button>
              </Link>
            </div>

          </Col>
        </Row>


        {/* Category */}
        <div className='Inter-Bold fs_16 mb-2 ms-1 flex-grow-1 mt-4 fs-xs-16'>Explore Packages by Theme</div>

        <div className='w-100'>
          {isLoading1 ? (
            <Loader type={'category'} mt={'mt-2'} />
          ) : (
            <Carousel
              responsive={responsive_pkg}
              swipeable={true}
              draggable={true}
              ssr={true}
              autoPlaySpeed={2500}
              keyBoardControl={true}
              customTransition="transform 1000ms ease-in-out"
              containerClass="carousel-container mt-2"
              itemClass="carousel-item1 "
              customLeftArrow={
                <CustomLeftArrow
                  direction="left"
                  mt={'mt-30px'}
                />
              }
              customRightArrow={
                <CustomLeftArrow
                  direction="right"
                  mt={'mt-30px'}
                />
              }
            >
              {cate && cate?.map((item, index) => {

                return (
                  <div key={item._id} className='mt-3 mb-4 rd_6 mx-3 pointer w-xxs-90 hover-me d-flex align-items-center flex-column' onClick={() => handleCallback(item._id, 'categoryId')}>
                    <div className="w-100 rd_50 bg-white hw_80px hw_70px d-flex align-items-center justify-content-center shadow-slowclean">
                      <img
                        src={`${img_base_url + item.icon}`}
                        className="hw_35px rd_6"
                        alt={item.name}
                      />
                    </div>
                    <div className='NunitoSans-SemiBold fs_14 text-muted text-center mt-2'>{item?.name}</div>
                  </div>
                )
              })}
            </Carousel>
          )}
        </div>

        {/* Types */}
        <div className='Inter-Bold fs_16 flex-grow-1 fs-xs-14 ms-1 mt-3 w-100'>Looking for Hotels</div>

        {isLoading1 ? (
          <div className='w-100'>
            <Loader type={'country'} />
          </div>
        ) : (
          <div className='w-100'>
            <Carousel
              responsive={responsive_pkg}
              swipeable={true}
              draggable={true}
              ssr={true}
              infinite={false}
              autoPlaySpeed={2500}
              keyBoardControl={true}
              customTransition="transform 1000ms ease-in-out"
              containerClass="carousel-container mt-2"
              itemClass="carousel-item1 "
              customLeftArrow={
                <CustomLeftArrow
                  direction="left"
                  mt={'mt-30px'}
                />
              }
              customRightArrow={
                <CustomLeftArrow
                  direction="right"
                  mt={'mt-30px'}
                />
              }
            >
              {hotelType && hotelType?.map((item, index) => {

                return (
                  <div key={item._id} className='mt-3 mb-4 rd_6 mx-2 pointer hover-me d-flex align-items-center flex-column' onClick={() => handleSave(item?._id, '')}>
                    <img alt='img' src={item?.image ? `${img_base_url + item?.image}` : `/images/ocean.png`} className='h_130px rd_10 w_120px w-xxs-99' />

                    <div className='NunitoSans-SemiBold fs_14 w-100 text_dark_medium text-center mt-2'>{item?.name}</div>
                  </div>
                )
              })}

            </Carousel>
          </div>
        )}


        {/* ===========================Tags section================================ */}
        <div className='w-100 mt-2 d-flex align-items-center flex-column justify-content-center'>
          {tagsList && tagsList?.locations?.length > 0 &&
            <div className='bg-white rd_6 p-3  mb-3 w-100'>
              <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Locations {data[0]?.countryData ? 'in ' + data[0]?.countryData?.name : ''}</h2>

              {tagsList ? (
                <div className='d-flex flex-wrap gap-2'>
                  {tagsList?.locations?.slice(0, showTags).map((it, index) => {

                    return (
                      <div key={it._id} onClick={() => openAbout(it?.name, it.id, it.item, 'location')} className=' hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                        Top places to visit in {it.name}
                      </div>
                    )
                  })}

                  {showTags == 30 && <div onClick={() => setShowTags(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                    See All
                    <i class="fa-solid fa-angles-right ml-5px"></i>
                  </div>}
                </div>
              ) : (
                <Spin className='ms-2 mb-2 thm-spin' />
              )}
            </div>
          }

          {tagsList && tagsList?.states?.length > 0 &&
            <div className='bg-white rd_6 p-3 mb-3 w-100'>
              <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States {data[0]?.countryData ? 'in ' + data[0]?.countryData?.name : ''}</h2>

              {tagsList ? (
                <div className='d-flex flex-wrap gap-2'>
                  {tagsList && tagsList?.states?.slice(0, showTags2).map((it, index) => {
                    return (
                      <div key={it._id} onClick={() => openAbout(it?.name, it.id, it._id, 'state')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                        Top places to visit in {it.name}
                      </div>
                    )
                  })}

                  {showTags2 == 30 && <div onClick={() => setShowTags2(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                    See All
                    <i class="fa-solid fa-angles-right ml-5px"></i>
                  </div>}
                </div>
              ) : (
                <Spin className='ms-2 mb-2 thm-spin' />
              )}
            </div>
          }

          <div className='bg-white rd_6 p-3 mb-3 w-100'>
            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>
            {tagsList ? (
              <div className='d-flex flex-wrap gap-2'>
                {tagsList && tagsList?.countries?.slice(0, showTags3).map((it, index) => {
                  return (
                    <div key={it._id} onClick={() => openAbout(it?.name, it.id, it._id, 'country')} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                      Top places to visit in {it.name}
                    </div>
                  )
                })}

                {showTags3 == 30 && <div onClick={() => setShowTags3(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                  See All
                  <i class="fa-solid fa-angles-right ml-5px"></i>
                </div>}
              </div>
            ) : (
              <Spin className='ms-2 mb-2 thm-spin' />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetails