import React from 'react'
import "../CSS/bd-coming-soon.css";

function ComingSoon() {
    return (
        <div className='mt-12 h_400px oveflow-hidden'>
            <img alt="state" className="img-fluid w-100 h-100" src={`/images/bg_comming.jpeg`} />

            <div className='rd_6 d-flex h-100 w-100 justify-content-center align-items-center pointer' style={{
                position: 'absolute',
                top: 0,
                borderRadius: 10,
                background: 'linear-gradient(to top, rgba(0,0,0,0.30), rgba(0,0,0,0.30)) ',
            }}>
                <div className="container text-center ">
                    <h1 className="page-title mt-3 text-white fs_40 text-shadow">Coming soon</h1>
                    <p className='Inter-Medium text-white text-shadow'>Stay connected with us</p>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon