import React, { useContext, useState } from 'react';
import { Offcanvas, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SignInModal from '../Pages/Auth/SignInModal';
import { MemContext } from '../ContextRedux/Context';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { svInfo, svMsgId, svReceiverDetails, svTripCate } from '../ContextRedux/Action';

const vendor_url = process.env.REACT_APP_VENDOR_URL;

const OffcanvasNav = ({ show, handleClose }) => {
  const user1 = useSelector(state => state.data.info)
  const { isLoading, unId, logout } = useContext(MemContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const outBro = async () => {
    try {
      handleClose()
      dispatch(svInfo({}))
      dispatch(svTripCate([]))
      dispatch(svMsgId({}))
      dispatch(svReceiverDetails({}))

      await logout()
      await googleLogout()
      window.location.href = '/';
    } catch (error) {
    }
  }

  return (
    <>
      <Offcanvas show={show} className='offcanvas-parent z-5' onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <div className='text_thm NunitoSans-Bold'>TravellingLark</div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Link className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none' onClick={() => handleClose()} to="/">Home</Link>
            <Link className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none' onClick={() => handleClose()} to="/packages">Packages</Link>
            <Link className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none' onClick={() => handleClose()} to="/hotels">Hotels</Link>
            {unId !== null && <Link className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none' onClick={() => handleClose()} to="/profile">Profile</Link>}

            {user1.tkn && (
              <>
                <Link to={`${vendor_url}`} target='_blank' className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none'>Travel Agent Zone</Link>
                <Link to={`${vendor_url}redirect/view-packages/${user1?.tkn}`} target='_blank' className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none'>List Your Packages</Link>
                <Link to={`${vendor_url}redirect/hotel/${user1?.tkn}`} target='_blank' className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none'>List Your Hotels</Link>
              </>
            )}
            <Link className='text_gray NunitoSans-Medium fs_14 mb-3 text-decoration-none' to="/post-leads" onClick={() => handleClose()}>Post Leads</Link>
            {/* <Link className='text_gray Source_Medium mb-2 text-decoration-none' to="/vehicles">Vehicle</Link> */}
            {/* <Link className='text_gray Source_Medium mb-2 fs_16 text-decoration-none' to="/guiders">Travel Guider</Link> */}
            {/* {unId !== null &&
              <>
                <div className={` NunitoSans-Medium mb-2 pointer bg-danger1 d-flex ${isShow ? 'text_thmdark' : 'text_gray'}`} onClick={() => setIsShow(!isShow)}>
                  <p className='m-0'>Menu</p>
                  <i className={`fa-solid fa-sort-${isShow ? 'down' : 'down'} ms-2`}></i>
                </div>

                {isShow && (
                  <div className='bg-warning1 bg_lightblue rd_6 p-2 ps-3 d-flex flex-column mb-2'>
                    <Link className='text_gray NunitoSans-Medium fs_14 mb-2 fs_14 text-decoration-none' onClick={()=> handleClose()} to="/my-travels">My Profile</Link>
                    <Link className='text_gray NunitoSans-Medium fs_14 mb-1 fs_14 text-decoration-none' to="/my-travels">My PostTravels</Link>
                  </div>
                )}
              </>
            } */}
            <div className='flex-grow-1 h-100' />

            {isLoading == true ? (
              <li className="nav-item bg-warning1 pt-0-5">
                <Spin className='thm-spin' />
              </li>
            ) : (
              unId == null ? (
                <div onClick={() => { openModal(); handleClose() }} className='position-absolute bottom-0 mb-3 NunitoSans-Medium fs_14 text_thm login-nav-link pt-1 mt-4 px-3 border_thm text-center m-0 h_35px w_40 rd_10 pt-1'>
                  Login
                </div>
              ) : (
                <div onClick={() => { outBro() }} className='position-absolute w-90p bottom-0 mb-3 NunitoSans-Medium fs_14 text_thm login-nav-link pt-1 mt-4 px-3 border_thm text-center m-0 h_35px rd_10 pt-1'>
                  Log Out
                </div>
              ))}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <SignInModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default OffcanvasNav;
