import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dateFormat } from './CommonFs';

const InvoiceP = () => {
    const location = useLocation();
    const details = location.state;


    // {
    //     "invoiceNumber": "389",
    //     "totalAmount": 300000,
    //     "currency": "INR",
    //     "payable": 300000,
    //     "customer": "Ravi ",
    //     "email": "ravi@gmail.com",
    //     "address": null,
    //     "number": null,
    //     "title": "Lemon Tree Hotel, Hisar",
    //     "date": "2024-09-01T13:18:23.629Z"
    // }

    // useEffect(() => {
    //     injectBootstrap3CSS();

    //     return () => {
    //         removeBootstrap3CSS();
    //     };
    // }, []);



    const company = {
        name: 'TravellingLark',
        phone: '+91 98898-98989',
        email: 'email@company.com',
        address: 'Haryana, India'
    };

    const date = dateFormat(details.date, 'dmy')


    return (
        <div className=' mt-6 pt-3' style={{ backgroundColor: '#eef4fd' }}>
            <div className="bg-warning1 container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="receipt-main rd_4 col-xs-10 col-sm-10 col-md-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
                        <div className="d-flex mt-4">
                            <div className=" text-left flex-grow-1">
                                <div className="receipt-right">
                                    <div className='Rubik-SemiBold fs_20'>{company.name}</div>
                                    <p><i className="fa fa-phone text-secondary fs_11"></i> {company.phone} </p>
                                    <p><i className="fa fa-envelope-o text-secondary fs_11"></i> <a href={`mailto:${company.email}`}>{company.email}</a></p>
                                    <p><i className="fa fa-location-arrow text-secondary fs_12"></i> {company.address}</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="receipt-right1 d-flex">
                                    <h3 className='Rubik-Regular text-end1 mt-0 fs_20 fs-xs-18 text-secondary'>INVOICE # {details.invoiceNumber}</h3>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="receipt-header receipt-header-mid">
                                <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                                    <div className="receipt-right">
                                        <div className='Rubik-SemiBold fs_18'>{details.customer}</div>
                                        <p><b className='Rubik-Regular mt-1'>Mobile :</b> {details.phone || 'Not Available'}</p>
                                        <p><b className='Rubik-Regular'>Email :</b> {details.email}</p>
                                        <p><b className='Rubik-Regular'>Address :</b> {details.address || 'Not Available'}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <table className="table table-bordered brc-secondary">
                                <thead className=''>
                                    <tr>
                                        <th className=''>Item Name</th>
                                        <th className=''>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {items.map((item, index) => ( */}
                                    <tr>
                                        <td className="col-md-9 Rubik-Regular">{details.title}</td>
                                        <td className="col-md-3 Rubik-Regular text-muted">{details.totalAmount}</td>
                                    </tr>
                                    {/* ))} */}
                                    <tr className=''>
                                        <td className=" text-end">
                                            <p><strong className='Rubik-Regular'>Total Amount: </strong></p>
                                            <p><strong className='Rubik-Regular'>Payable Amount: </strong></p>
                                            <p><strong className='Rubik-Regular'>Balance Due: </strong></p>
                                        </td>
                                        <td>
                                            <p><strong className='Rubik-Regular fs_13'>{details.totalAmount}</strong></p>
                                            <p><strong className='Rubik-Regular fs_13'>{details.payable || ''}</strong></p>
                                            <p><strong className='Rubik-Regular fs_13'>{details.balanceDue || '0'}</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">
                                            <h2><strong className='Rubik-Regular fs_16'>Total: </strong></h2>
                                        </td>
                                        <td className="text-left text-danger">
                                            <h2><strong className='Rubik-Regular text_thm fs_16'>{details.payable}</strong></h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="row"> */}
                            <div className="d-flex  receipt-header receipt-header-mid receipt-footer">
                                <div className="flex-grow-1">
                                    <div className="receipt-right">
                                        <p><b className='Rubik-Regular'>Date :</b> {date}</p>
                                        <h5 className='mb-4 Rubik-SemiBold mt-3' style={{ color: 'rgb(140, 140, 140)' }}>Thanks for shopping.!</h5>

                                        <button className="btn btn-success fs_14" id="invoice-print"><i className="fa fa-print"></i> Print Invoice</button>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="">
                                        <h1 className='Rubik-Regular'>Stamp</h1>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceP;

