const initialState = {
    data: {},
    info: {},
    category: [],
    receiver_details: {},
    msgId: '',
    reviewId: {}
};

const Reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case 'SV_INFO':
            return {
                ...state,
                info: action.payload,
            };
        case 'SV_TRIP_CATE':
            return {
                ...state,
                category: action.payload
            };
        case 'SV_RECEIVER_DETAILS':
            return {
                ...state,
                receiver_details: action.payload
            };
        case 'SV_MSG_ID':
            return {
                ...state,
                msgId: action.payload
            };
        case 'SV_REVIEW_ID':
            return {
                ...state,
                reviewId: action.payload
            };
        case 'RESET_STATE':
            return initialState;
        default:
            return state;
    }
};

export default Reducer;
