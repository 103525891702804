import { createBrowserRouter, useNavigate } from "react-router-dom";
import PkgIndex from "../Pages/Packages/PkgIndex";
import Nav from "./Nav";
import ScrollToTop from "../Componets/ScrollToTop";
import QueryDetails from "../Pages/Dashboard/Query/QueryDetails";
import AddQuery from "../Pages/Dashboard/Query/AddQuery";
import Queries from "../Pages/Dashboard/Query/Queries";
import Footer from "../Componets/Footer";
import Profile from "../Pages/Menu/Profile";
import Settings from "../Pages/Menu/Settings";
import MyQueries from "../Pages/Menu/MyQueries";
import PkgDetails from "../Pages/Packages/PkgDetails";
import HotelIndex from "../Pages/Hotels/HotelIndex";
import HotelDetails from "../Pages/Hotels/HotelDetails";
import Guider from "../Pages/Guider/Guider";
import Vehicleindex from "../Pages/Vehicle/Vehicleindex";
import MyBookings from "../Pages/Menu/MyBookings";
import VehicleList from "../Pages/Vehicle/VehicleList";
import GuiderDetails from "../Pages/Guider/GuiderDetails";
import PrivacyPolicy from "../Pages/FooterLinks/PrivacyPolicy";
import TC from "../Pages/FooterLinks/TC";
import RefundCancel from "../Pages/FooterLinks/RefundCancel";
import Blogs from "../Pages/FooterLinks/Blogs/Blogs";
import BlogDetails from "../Pages/FooterLinks/Blogs/BlogDetails";
import About from "../Pages/FooterLinks/About";
import STicket from "../Pages/Ticket/STicket";
import TicketDetails from "../Pages/Ticket/TicketDetails";
import PkgList from "../Pages/Packages/PkgList";
import VehicleDetails from "../Pages/Vehicle/VehicleDetails";
import React, { useState } from "react";
import InvoiceP from "../Componets/InvoiceP";
import ContactForm from "../Pages/FooterLinks/ContactForm";
import TripsAbout from "../Pages/Packages/TripsAbout";
import NotFound from "../Componets/NotFound";
import VendorDetails from "../Pages/Dashboard/VendorDetails";
import ProposalDetails from "../Pages/Dashboard/Query/ProposalDetails";
import Home from "../Pages/Dashboard/Home";
import ComingSoon from "../Componets/ComingSoon";
import SignInModal from "../Pages/Auth/SignInModal";
import MessagingPopup from "../Pages/Dashboard/MessagingPopup";
import { MsgNoti } from "../Componets/Modal";


const ModalWrapper = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(true);

    const handleCloseModal = () => {
        navigate('/')
        setModalVisible(false);
    };

    return (
        <SignInModal isOpen={modalVisible} onClose={handleCloseModal} />
    );
};

export const RouterParent = createBrowserRouter([
    {
        path: '/',
        element: (<><ScrollToTop /><MsgNoti /><Nav /><Home /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/vendor',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><VendorDetails /><Footer /><MessagingPopup type={'common'} /></>)
    },

    // Pkg
    {
        path: '/packages',
        element: <><ScrollToTop /><Nav /><MsgNoti /><PkgIndex /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/package/list',
        element: <><ScrollToTop /><Nav /><MsgNoti /><PkgList /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/package/:slug/:id',
        element: <><ScrollToTop /><Nav /><MsgNoti /><PkgDetails /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/:parent/:slug/:id',
        element: <><ScrollToTop /><Nav /><MsgNoti /><TripsAbout /><Footer /><MessagingPopup type={'common'} /></>
    },
    // Hotels
    {
        path: '/hotels',
        element: <><ScrollToTop /><Nav /><MsgNoti /><HotelIndex /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/hotel/:slug/:id',
        element: <><ScrollToTop /><Nav /><MsgNoti /><HotelDetails /><Footer /><MessagingPopup type={'common'} /></>
    },
    // Query
    {
        path: '/post-travel',
        element:
            <>
                <ScrollToTop />
                <Nav /><MsgNoti />
                <AddQuery />
                <Footer />
                <MessagingPopup type={'common'} /></>
    },
    {
        path: '/post-travel/details',
        element: (<><ScrollToTop /><Nav type="queryDetails" /><QueryDetails /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/post-leads',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><Queries /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/proposal/details',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><ProposalDetails /><Footer /><MessagingPopup type={'common'} /></>)
    },
    // Guider
    {
        path: '/guiders',
        element: <><ScrollToTop /><Nav /><MsgNoti /><Guider /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/guiders/details',
        element: <><ScrollToTop /><Nav /><MsgNoti /><GuiderDetails /><Footer /><MessagingPopup type={'common'} /></>
    },

    // Vehicle
    {
        path: '/vehicles',
        element: <><ScrollToTop /><Nav /><MsgNoti /><Vehicleindex /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/vehicles/list',
        element: <><ScrollToTop /><Nav /><MsgNoti /><VehicleList /><Footer /><MessagingPopup type={'common'} /></>
    },
    {
        path: '/vehicles/details',
        element: <><ScrollToTop /><Nav /><MsgNoti /><VehicleDetails /><Footer /><MessagingPopup type={'common'} /></>
    },
    // Prfoile menu
    {
        path: '/profile',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><Profile /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/settings',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><Settings /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/my-travels',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><MyQueries /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/my-proposals',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><MyQueries /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/mybookings',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><MyBookings /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/privacy-policy',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><PrivacyPolicy /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/terms-and-conditions',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><TC /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/refund-and-cancellation',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><RefundCancel /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/blogs',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><Blogs /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/blog/:slug/:id',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><BlogDetails /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/about-us',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><About /><Footer /><MessagingPopup type={'common'} /></>)
    },

    {
        path: '/user/tickets',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><STicket /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/user/ticket-details',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><TicketDetails /><Footer /><MessagingPopup type={'common'} /></>)
    },

    // invoice

    {
        path: '/payment-receipt',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><InvoiceP /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/contact-us',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><ContactForm /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/not-found',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><NotFound /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '*',
        element: (
            <>
                <ScrollToTop />
                <Nav /><MsgNoti />
                <NotFound />
                <Footer />
                <MessagingPopup type={'common'} /></>
        ),
    },
    {
        path: '/coming-soon',
        element: (<><ScrollToTop /><Nav /><MsgNoti /><ComingSoon /><Footer /><MessagingPopup type={'common'} /></>)
    },
    {
        path: '/signin',
        element: (<ModalWrapper />)
    },


])


