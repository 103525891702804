import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import Map from '../../Hotels/Map';
import RatingStars from '../../../Componets/RatingStars';
import { CommonAPI } from '../../../Componets/CommonAPI';
import { dec, enc } from '../../../Componets/SecRes';
import { avgRatingCalculate, dateFormat, reviewOfSummary } from '../../../Componets/CommonFs';
import { tstSuccess } from '../../../Componets/HotToast';
import SignInModal from '../../Auth/SignInModal';
import { useDispatch, useSelector } from 'react-redux';
import { FeedbackModal } from '../../../Componets/Modal';
import MessagingPopup from '../MessagingPopup';
import { io } from 'socket.io-client';
import { svReceiverDetails } from '../../../ContextRedux/Action';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const socket_url = process.env.REACT_APP_SOCKET_URL;
const socket_local_url = process.env.REACT_APP_SOCKET_LOCAL;
// const socket = io(socket_url);

function ProposalDetails() {
    const location = useLocation();
    const dispatch = useDispatch();
    const state = location?.state;

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [list2, setList2] = useState([])
    const [details, setDetails] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [hideBtn, setHideBtn] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const socketRef = useRef(null);

    const user = useSelector(reducer => reducer.data?.info?.user)
    const user_token = useSelector(reducer => reducer.data?.info?.tkn)




    useEffect(() => {
        if (user_token) {
            socketRef.current = io(socket_url, {
                query: {
                    token: user_token
                }
            });
        }
        getDeatils();
    }, [])

    const getDeatils = async () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `proposalDetail?id=${state.id}`, responseBack2)
    }

    const responseBack2 = useCallback(res => {
        if (res.status === true) {
            const dt = res.data && res?.data;
            const dec1 = dec(dt, key);
            setDetails(dec1);
        } else {
        }
        setIsLoading(false)
    })

    const submitDate = dateFormat(details[0]?.createdAt, 'dmy')

    const vendor = details && details[0]?.vendorId || ''
    const since = dateFormat(vendor?.createdAt, 'dmy')
    const accpeted = details[0]?.accept


    const acceptStatus = () => {
        const send = {
            id: state.id,
            queryId: state.queryId,
        }
        const enc1 = enc(send, key);

        setIsLoading1(true)
        CommonAPI(enc1, 'POST', 'proposalAccept', (res) => {
            if (res.status === true) {
                tstSuccess('Proposal Accepted Successfully!!')
            } else {
                if (res.message == 'Invalid token') {
                    setIsModalOpen(true)
                }
            }
            setIsLoading1(false)
        })
    }

    const handleStartChat = async (name, id) => {
        if (user?._id) {
            const createO = { sender_id: user?._id, receiver_id: id }
            const create = enc(createO, key)
            socketRef.current.emit('create_chat', create, (res) => {
                dispatch(svReceiverDetails({ receiver_id: id, name: name, chat_id: res.chat_id }))
            })
            setIsModalOpen1(true)
        } else {
            setIsModalOpen(true)
        }
    }

    return (
        <div className='d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5' style={{ backgroundColor: '#eef4fd' }}>

            <div className='w_95 mt-4 d-flex align-items-center flex-column w-100'>

                <Row className='bg-white rd_4 p-3 w-100'>

                    <Col className='bg1-success'>
                        <div className='bg1-secondary'>
                            <div className='Rubik-Medium '>{details[0]?.vendorId?.business_name}</div>
                            <div className='Rubik-Regular mb-1 text-secondary fs_13 mt-2'><strong>Proposal Submit:</strong> {submitDate}</div>
                            <div className='Rubik-Regular mb-1 text-secondary fs_13'><strong className=''>Description:</strong> {details[0]?.description}</div>
                        </div>
                    </Col>
                </Row>

                <div className='Rubik-Medium w-100 mt-4'>Vendor Details</div>
                <Row className='bg-white rd_4 p-3 w-100 mt-2'>
                    <Col className='bg1-warning mb-md-3'>

                        <div className='Rubik-Regular fs_15 mb-3'><strong className=''>{vendor?.business_name}</strong></div>
                        <div className='mb-2 text-secondary fs_14 text-capitalize'>
                            <i className="fa-regular fa-user mr_10 text-primary"></i>
                            {vendor?.first_name || ''} {vendor?.last_name || ''}
                        </div>

                        <div className='mb-2 text-secondary fs_14 '>
                            <i className="bi bi-geo-alt-fill mr_10 text-primary"></i>
                            {vendor?.address}
                        </div>

                        <div className='Rubik-Regular mb-1 text-secondary fs_13'>
                            <i className="fa-solid fa-calendar-days fs_12 mr_10 text-danger"></i>
                            <strong>Member Since: </strong>
                            {since}
                        </div>
                        {(user?._id == state?.queryUserId) && (
                            <>
                                <div className={`mt-3 bg-gradient-${accpeted ? 'success' : 'thm'} h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center`} onClick={() => accpeted ? null : acceptStatus()}>
                                    {isLoading1 ? <Spinner size='sm' /> : <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className={`fa-solid fa-${accpeted ? 'check-double' : 'check'} fa-fade me-1 fs_13`}></i> {accpeted ? 'Accepted' : 'Accept Proposal'}</p>}
                                </div>

                                <div className='mt-3 bg-gradient-blue h_35px d-inline-flex px-3 mr_10 rd_6 justify-content-center align-items-center' onClick={() => handleStartChat(vendor?.business_name, vendor?._id)}>
                                    <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className="fa-regular fa-comment-dots fa-fade me-1 fs_13"></i> Chat with US</p>
                                </div>

                                <div className='mt-3 bg-gradient-dark h_35px d-inline-flex px-3 rd_6 justify-content-center align-items-center' onClick={() => (hideBtn || details?.[0]?.reviewStatus == 1) ? null : setOpen3(true)}>
                                    <p className='Rubik-Regular fs_12 text-white pointer m-0'><i className={`fa-regular fa-star fa-fade me-1 fs_13`}></i> {(hideBtn || details?.[0]?.reviewStatus == 1) ? 'Already Submitted Review' : 'Write a Review'}</p>
                                </div>
                            </>
                        )}

                    </Col>

                    <Col md={12} lg={6} xl={6} className='bg1-success '>
                        <Map address={vendor?.address} />
                    </Col>

                </Row>
            </div>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />

            {/* For Submit Feedback */}
            <FeedbackModal isVisible={open3} onClose={setOpen3} type={'proposal'} id={state.id} vendorId={vendor?._id} callback={setHideBtn} />

            {/* <MessagingPopup openPopup={isModalOpen1} closePopup={setIsModalOpen1} type={'query'} /> */}

        </div>
    )
}

export default ProposalDetails