import React, { useCallback, useContext, useEffect, useState } from 'react'
import Nav from '../../../Routes/Nav'
import Footer from '../../../Componets/Footer'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { tstSuccess } from '../../../Componets/HotToast';
import { MemContext } from '../../../ContextRedux/Context';
import { CommonAPI } from '../../../Componets/CommonAPI';
import { CountriesDrop, CurrencyDrop } from '../../../Componets/Countries';
import { useLocation, useNavigate } from 'react-router-dom';
import { dec, enc } from '../../../Componets/SecRes';
import { customStyle, dateFormat, lookingForOptions, useCountryGet } from '../../../Componets/CommonFs';
import SignInModal from '../../Auth/SignInModal';
import { Checkbox, DatePicker, Modal } from 'antd';
import moment from 'moment';
import Select from 'react-select'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Counter from '../../Hotels/Counter';

const key = process.env.REACT_APP_KEY;


function AddQuery() {
    const { userInfo } = useContext(MemContext)
    const location = useLocation();
    const navigate = useNavigate();

    const editItem = location?.state;



    const [checkedOptions, setCheckedOptions] = useState(editItem?.looking_for || ['Packages', 'Hotel']);
    const [desc, setDesc] = useState(editItem?.description || '');
    const [country1, setCountry] = useState(editItem?.queryCountry[0]?._id || '');
    const [duration, setDuration] = useState(editItem?.duration || '');
    const [person, setPerson] = useState(editItem?.no_of_person || '');
    const [currency, setCurrency] = useState(editItem?.currency?._id || '66e3fb6dbee3abe287510a1b');
    const [budget, setBudget] = useState(String(editItem?.budget) || '');
    const [adult, setAdult] = useState(editItem?.adult || '');
    const [child, setChild] = useState(String(editItem?.child) || '');
    const [selectedDate, setSelectedDate] = useState('');
    const [inputDate, setInputDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [err, setError] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const today = moment();
    const minDate = today.clone().add(0, 'days');
    const [open, setOpen] = useState(false);
    const [adultChild, setAdultChild] = useState({
        adults: 1,
        children: 0
    });

    const [dates, setDates] = useState([]);


    useEffect(() => {
        if (dates.length === 0) {
            const currentDate = new Date();
            const nextDate = new Date()
            nextDate.setDate(currentDate.getDate() + 1)
            const parsedDate = dayjs(nextDate || null)
            const dateJson = parsedDate ? parsedDate.toDate() : null;
            const formated = dateFormat(dateJson, 'dmy')

            setSelectedDate(formated.replace(/\//g, '-'))
            setDates(parsedDate)
        }
    }, [dates.length === 0]);

    useEffect(() => {
        if (editItem?.travelDate) {
            const date = editItem?.travelDate;

            const parsedDate = dayjs(date || null);

            const dateJson = parsedDate ? parsedDate.toDate() : null;
            const formated = dateFormat(dateJson, 'dmy')

            setSelectedDate(formated.replace(/\//g, '-'))
            setDates(parsedDate);
        }
    }, []);

    const handleDate = (date) => {
        const dateJson = date ? date.toDate() : null;
        const formated = dateFormat(dateJson, 'dmy')
        setSelectedDate(formated.replace(/\//g, '-'))
        setDates(date);
    };
    const user = useSelector(reducer => reducer.data?.info)


    const val = () => {
        const err = {};
        if (desc.trim().length < 10) {
            err.desc = "Please enter minimum 10 characters!"
        }
        if ((Number(budget) <= 100 || isNaN(budget))) {
            err.bugt = "Please enter a valid budget!"
        }
        if (!duration.trim()) {
            err.du = "Enter valid duration!"
        }
        if (selectedDate == '') {
            err.date = 'Invalid date!'
        }
        if (!country1) {
            err.country = "Select valid country!"
        }
        if (!currency) {
            err.currency = "Select valid currency!"
        }
        if ((!adultChild.adults || isNaN(adultChild.adults))) {
            err.adult = "Select Persons!"
        }
        if (checkedOptions.length == 0) {
            err.for = "Please select at least one!"
        }

        setError(err)
        return Object.keys(err).length === 0
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            description: desc,
            currency: currency,
            budget: Number(budget),
            duration: duration,
            countryId: country1,
            userId: userInfo?._id,
            looking_for: checkedOptions,
            id: editItem?._id || '',
            adult: Number(adultChild.adults),
            child: Number(adultChild.children),
            travelDate: selectedDate
        };

        const enc1 = enc(data, key)

        if (!user?.tkn) {
            setIsModalOpen(true)
            return
        }

        if (val()) {
            setIsLoading(true)
            CommonAPI(enc1, 'POST', 'createQuery', responseBack)
        }
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            setDesc('')
            setCurrency('')
            setCountry('')
            setSelectedDate('')
            setDuration('')
            setBudget('')
            setAdult('')
            setChild('')
            setDates('')
            setCheckedOptions([])
            tstSuccess('Travel Lead Submitted Successfully')
            const dec1 = dec(res.data, key)

            navigate('/post-travel/details', { state: dec1._id })
        } else {
            if ((res.message == 'Invalid token' || res.message == 'No token provided')) {
                setIsModalOpen(true)
            }
            setError({ submit: 'Something went wrong!' })
        }
        setIsLoading(false)
    })

    const handleCheckboxChange = (option) => {
        setCheckedOptions(prevState =>
            prevState.includes(option)
                ? prevState.filter(item => item !== option)
                : [...prevState, option]
        );
    };

    const handleOk = (ty) => {
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };


    return (
        <div className='bg-primary1 min-vh-100 d-flex flex-column align-items-center mt-12 pt-3 p-0 pb-5'>

            <div className="w_92 mt-4 d-flex align-items-center flex-column w-10 position-relative">
                {/* scrollable content */}
                <div className="text-center Inter-Bold fs-2">Post your <span className='text_thmdark'>travel</span> details</div>
                <Row className='bg-warning1 w-100 position-relative'>
                    <Col md={6} lg={4} className='d-flex justify-content-center h_350px query-right-img mt-5'>
                        <img src='/images/man_with_bag_rock.jpeg' className='img-fluid rd_6' />
                        {/* <img src='https://img.freepik.com/premium-photo/clean-flat-illustration-tourism-industry_1004054-5501.jpg?w=996' className='img-fluid rd_6' /> */}
                    </Col>
                    <Col className='bg-success1'>
                        <div className='scrollable-content position-relative'>
                            <form onSubmit={handleSubmit} className='mt-5'>

                                <div className="form-group mb-3 position-relative">
                                    <label className='Rubik-Regular fs_13 text-muted mb-1'>Enter your Travel</label>
                                    <textarea name="description" placeholder='Enter your travel details' rows={4} className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" value={desc} onChange={(event) => setDesc(event.target.value)} />
                                    {err.desc && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.desc}</p>}
                                </div>

                                <Row className='d-flex bg-warning1 position-relative'>
                                    <Col xl={5} className="form-group">
                                        <label className='Rubik-Regular fs_13 text_gray mb-1'>Travel Budget</label>
                                        <div className="form-group mb-3 flex-grow-1">
                                            <div className='d-flex mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium overflow-hidden'>
                                                <CurrencyDrop onChange={setCurrency} selected={currency} type={'simple'}/>
                                                <input type="number" name="budget" placeholder='100' max="100000" maxLength={6} className="w-auto form-control outline-0 border-0"
                                                    value={budget}
                                                    onChange={(event) => {
                                                        const value = event.target.value
                                                        if (/^\d{0,6}$/.test(value)) {
                                                            setBudget(value)
                                                        }
                                                    }} />
                                            </div>

                                            {err.bugt && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.bugt}</p>}
                                        </div>
                                        {err.currency && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.currency}</p>}
                                    </Col>

                                    <Col xl={7} className="form-group mb-3 flex-grow-1">
                                        <label className='Rubik-Regular fs_13 text-muted mb-1'>Travel Duration</label>
                                        <input type="text" name="duration" placeholder='Duration ex. 2 Days 1 Night' className="form-control mb-2 fs_13 h_38px rd_10 border_gray Rubik-Regular text_dark_medium" value={duration} onChange={(event) => setDuration(event.target.value)} />
                                        {err.du && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.du}</p>}
                                    </Col>

                                    <Col xl={3} className="form-group mb-3 flex-grow-1">
                                        <label className='Rubik-Regular fs_13 text-muted mb-1'>Travel Date</label>
                                        <DatePicker
                                            className='border_gray w-100 flex-grow-1 rd_10 outline-none text_gray fs_14 h_38px m-0'
                                            onChange={handleDate}
                                            value={dates || null}
                                            format={'DD-MM-YYYY'}
                                            disabledDate={(current) => current && current < minDate}
                                        />
                                        {err.date && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.date}</p>}
                                    </Col>

                                    <Col xl={4} className="form-group flex-grow-1">
                                        <label className='Rubik-Regular fs_13 text-muted mb-1'>Travel Country</label>
                                        <CountriesDrop onChange={setCountry} selected={country1} />
                                        {err.country && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.country}</p>}
                                    </Col>

                                    <Col className="form-group flex-grow-1">
                                        <label className='Rubik-Regular fs_13 text-muted mb-1'>Persons</label>

                                        <div className='form-control fs_13 h_38px rd_10 border_gray d-flex align-items-center  text_dark_medium' onClick={() => setOpen(true)}>{adultChild.adults} Adults • {adultChild.children} Childrens</div>
                                        {err.adult && <p className='fs_13 mb-2 pt-0 mt-0 text-danger'>{err.adult}</p>}
                                    </Col>

                                </Row>


                                <div className="form-group mb-3 mr_10">
                                    <label className='Inter-Bold fs_13 text-muted mb-2'>Looking For</label>
                                    <div className='d-flex align-items-center flex-wrap'>
                                        {lookingForOptions.map(option => (
                                            <div className='Source_Regular mb-5px mr_10 fs_14 text-muted d-flex align-items-center pointer' key={option}>
                                                <Checkbox checked={checkedOptions.includes(option)}
                                                    onChange={() => handleCheckboxChange(option)} className='fs_13 Source_Regular custom-checkbox css-dev-only-do-not-override-1x0dypw'>
                                                    {option}
                                                </Checkbox>
                                            </div>
                                        ))}
                                    </div>
                                    {err.for && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.for}</p>}
                                </div>

                                <button type="submit" className="btn bg-gradient-thm text-white px-5 rd_6 btn-block mt-3" disabled={isLoading}>
                                    {isLoading ? <Spinner size='sm' /> : editItem?._id == undefined ? 'Submit' : 'Edit Travel Lead'}
                                </button>
                                {err.submit && <p className='fs_13 mb-2 pt-0 mt-1 text-danger'>{err.submit}</p>}
                            </form>
                        </div>
                    </Col>

                </Row>

            </div>

            {/* <div className='bg-warning bg_shape'>
                <img src='/shape/shape-bg2.png' className='w-100 h-100' />
            </div> */}

            <div className='d-flex flex-wrap container mt-5 bg-warning1'>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Post your travel details</p>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Get quotation from multiple verified vendors</p>
                <p className='text_gray Source_Regular me-3'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Choose suitable offer from multiple quotation</p>
                <p className='text_gray Source_Regular'><i className='fa fa-check-circle mr_10 text_thmdark'></i>Enjoy your trip</p>
            </div>

            <Modal
                title="Room & Guest"
                className='Source_Medium text_gray w-xs-auto mx-xs-4'
                styles={{
                    body: {
                        padding: '0 20px', height: 'auto'
                    },
                    header: {
                        padding: '15px 0px 0 15px',
                    },
                    footer: {
                        padding: '10px'
                    }
                }}
                open={open}
                onOk={() => { handleOk(); }}
                onCancel={() => setOpen(false)}
            >
                <Counter details={adultChild} setDetails={setAdultChild} type={'package'} />
            </Modal>

            {/* Modal Sigin */}
            <SignInModal isOpen={isModalOpen} onClose={setIsModalOpen} />
        </div>
    )
}

export default AddQuery