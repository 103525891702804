import React, { useCallback, useEffect, useState } from 'react'
import { CustomLeftArrow } from '../../Componets/CustomArrow';
import { StatesList } from '../../Componets/Countries';
import { CommonAPI } from '../../Componets/CommonAPI';
import { dec } from '../../Componets/SecRes';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { svTripCate } from '../../ContextRedux/Action';
import CarouselMe_D from '../../Componets/CarouselMe';
import Carousel from 'react-multi-carousel';
import { openInNewTab } from '../../Componets/CommonFs';
import { Row } from 'react-bootstrap';
import { Spin } from 'antd';
import { Loader } from '../../Componets/Loader';

const key = process.env.REACT_APP_KEY;
const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

const responsive_8 = {
    desktop1: {
        breakpoint: { max: 2000, min: 1150 },
        items: 4,
        slidesToSlide: 4
    },
    desktop: {
        breakpoint: { max: 1150, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 828 },
        items: 3,
        slidesToSlide: 3
    },
    mobile: {
        breakpoint: { max: 828, min: 560 },
        items: 2,
        slidesToSlide: 2
    },
    mobilex: {
        breakpoint: { max: 560, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


function PkgIndex() {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentMonth = new Date().getMonth();
    const ordersMonth = [
        ...months.slice(currentMonth),
        ...months.slice(0, currentMonth)
    ]

    const [selected, setSelected] = useState(ordersMonth[0]);
    const [state, setState] = useState({ cID: '', month: [ordersMonth[0]], location: '', categoryId: [] })
    const [cName, setCName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [monthClicked, setMothClicked] = useState(false);
    const [reloadAgain, setReloadAgain] = useState(0);
    const [showTags, setShowTags] = useState(30);
    const [showTags1, setShowTags1] = useState(30);
    const [showTags2, setShowTags2] = useState(30);
    const [list, setList] = useState([])
    const [pkgHomeList, setPkgHomeList] = useState([]);
    const [pkgList1, setPkgList1] = useState([]);
    const [pkgList2, setPkgList2] = useState([]);
    const [tagsList3, setTagsList3] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        tripCateList();
        homePackageList();
    }, [reloadAgain]);

    const tripCateList = () => {
        setIsLoading(true)
        CommonAPI({}, 'GET', `tripCategoryList`, responseBack)
    }

    const responseBack = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setList(dec1?.tripcategory)

            const categories = dec1?.tripcategory || [];
            dispatch(svTripCate(categories))
        } else {
        }
        setIsLoading(false)
    })


    const homePackageList = () => {
        setIsLoading1(true)
        setTagsList3([])
        CommonAPI({}, 'GET', `homePackageList?country=${state.cID || ''}&location=${state.location || ''}&month=${state.month || ''}`, responseBack1)

        // if (!monthClicked) {
        setIsLoading2(true)
        CommonAPI({}, 'GET', `topTagsList?type=country&typeId=${Number(state.digit) || ''}`, responseBack2)
        // }
    }

    const responseBack1 = useCallback(res => {
        if (res.status === true) {
            const dec1 = dec(res?.data, key)


            setPkgHomeList({ countries: dec1?.countries, locations: dec1?.locations })
            setPkgList1(dec1?.packages)
            setPkgList2(dec1?.monthlypackages)
        }

        setIsLoading1(false)
    })

    const responseBack2 = useCallback(res => {

        if (res.status === true) {
            const dec1 = dec(res?.data, key)
            setTagsList3(dec1)
        }

        setIsLoading2(false)
    })

    const listHome = Object.keys(pkgHomeList).length > 0 && pkgHomeList

    const handleCallback = async (value, type) => {
        setMothClicked(false);

        if (state?.cID == value) {
            const update = { cID: value, month: [], location: '', categoryId: '', cName: cName }
            setState(update);

            // homePackageList(update)
            localStorage.setItem('pkgHome', JSON.stringify(update));
        } else {
            const rn = Math.floor(Math.random() * 9999)

            setState(prevValues => {
                const newState = {
                    ...prevValues,
                    cName: cName,
                    [type]: type == 'month' ? [value] : value
                }
                localStorage.setItem('pkgHome', JSON.stringify(newState));
                if (type !== 'categoryId') {
                    setReloadAgain(rn)
                    // homePackageList(newState)
                }

                if (type == 'categoryId') {
                    goToPkgList()
                }
                return newState;
            })
        }
    }


    const goToPkgList = (type) => {
        const pkgHome = localStorage.getItem('pkgHome');
        const pkgHomeParams = JSON.parse(pkgHome)

        const month = type == 'month' ? pkgHomeParams?.month : []
        const newState = {
            ...pkgHomeParams,
            cName: cName,
            month
        }

        if (state?.cID) {
            localStorage.setItem('searchParams', JSON.stringify(newState));

            navigate('/package/list')
        }
    }

    const isVisible = listHome?.countries?.length > 0


    return (
        <div className='d-flex flex-column align-items-center bg-white mt-12 pt-3 p-0 bg-light' style={{ backgroundColor: '#f9fafb' }}>

            {isLoading1 && !monthClicked && (
                <div className='d-flex justify-content-center mb-4'>
                    <Spin className='thm-spin' />
                </div>
            )}
            <div className='w_95 mt-4'>
                {/* Country & State list */}
                <CarouselMe_D countries={listHome?.countries || []} img_base_url={img_base_url} callback={handleCallback} digitId={handleCallback} c_Name={setCName} />

                {!isLoading1 && listHome?.locations?.length > 0 && <h2 className="mb-2 Rubik-SemiBold fs_16 mb-3 mt-4 ms-2">Popular Locations in {cName}</h2>}
                {isLoading1 && !monthClicked ? (
                    <div className='w-100'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-3 mt-4 ms-2">Popular Locations in {cName}</h2>
                        <Loader type={'category'} mt={'mt-3'} />
                    </div>
                ) : (
                    <StatesList data={listHome?.locations || []} selected={state.location || ''} callback={handleCallback} />
                )}

                {/* ===========================2nd section================================ */}

                <div className='bg-warning1 d-flex align-items-center mt-4-5 me-2 justify-content-between'>
                    <div className="Rubik-SemiBold fs_16 ms-2 min-line one">Popular Packages in {cName}</div>
                    {pkgList1 && pkgList1.length > 0 && (
                        <div className='d-flex align-items-center pointer' onClick={() => goToPkgList('cId')}>
                            <div className='Rubik-Regular text_thmdark fs_14'>View All</div>
                            <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                        </div>
                    )}
                </div>

                {isLoading1 && !monthClicked ? (
                    <div className='w-100'>
                        <Loader type={'pkg'} />
                    </div>
                ) : (
                    pkgList1 && pkgList1.length > 0 ? (
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            responsive={responsive_8}
                            ssr={true}
                            autoPlay
                            autoPlaySpeed={2500}
                            keyBoardControl={true}
                            customTransition="transform 1000ms ease-in-out"
                            containerClass="carousel-container mt-2"
                            itemClass="carousel-item1 "
                            customLeftArrow={
                                <CustomLeftArrow
                                    direction="left"
                                />
                            }
                            customRightArrow={
                                <CustomLeftArrow
                                    direction="right"
                                />
                            }
                            removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                            dotListClass="custom-dot-list-style"
                        >
                            {pkgList1 && pkgList1?.slice(0, 4).map((item, index) => {
                                const lowPrice = item.discountPrice
                                const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                const vendor = item?.vendorId
                                return (
                                    <div key={item._id} className='hover-highlight-pkg h_270px pkg-btn-hover border_white br-2 mt-3 mb-4 rd_6 mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                        <Link state={{ type_id: item._id, type: 'Package', cId: state?.cID }} to={`/package/${item?.slug}/${item.id}`} target='_blank' className='text-decoration-none hover-me'>
                                            <img alt='img' src={`${img_base_url + item?.image}`} className='h_150px w-100 rd_4' />

                                            <div className='p-2-5'>
                                                <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                <p className='NunitoSans-Regular fs_11 text_gray2 mini-line-1'>By {vendor?.business_name}</p>

                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                                        {lowPrice != null && lowPrice > 0 && (
                                                            <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                                        )}
                                                    </div>
                                                    <div className='book-now border_thmdark rounded-5 fs_12 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </Carousel>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No popular packages available</div>
                        </div>
                    )
                )}

                {/* Trip categories */}
                <div className="container text-center bg-danger2 mt-4">
                    <h2 className="mb-2 Rubik-SemiBold fs_20 fs-xs-18 mb-4">Trip Categories</h2>
                </div>

                <div className='d-flex align-items-center justify-center bg-success1'>
                    <div className="row row-remove scrollable-container w-90p m-0 d-flex justify-content-center justify-content-initial align-items-start">
                        {list && list.map((item, index) => {
                            return (
                                <div key={index} className="xs-1-5 sm-1-5 md-1-5 lg-1-2 xl-1-5 mb-4" onClick={() => handleCallback([item._id], 'categoryId')}>
                                    <div className="bg-success1 border_gray1 border_gray1 d-flex flex-column align-items-center justify-content-center pointer">
                                        <div className="w-100 rd_50 bg-white hw_80px hw_70px d-flex align-items-center justify-content-center shadow-slowclean">
                                            <img
                                                src={`${img_base_url + item.icon}`}
                                                className="hw_35px rd_6"
                                                alt={item.name}
                                            />
                                        </div>
                                        <p className="m-0 mt-2 fs_14 Source_Medium text-muted text-center">
                                            {item.name}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Months */}
                {isVisible && (
                    <div className='bg_thmlightdark mt-5 mb-3 mx-0-7 pt-4 pb-4 rd_6 d-flex flex-column align-items-center'>
                        <div className='text-center d-flex mb-3 align-center justify-center w-100'>
                            <h2 className="Rubik-SemiBold fs_20 flex-column text-end flex-grow-1">Month wise Trips</h2>

                            <div className='bg-warning1 d-flex align-items-center me-2 flex-grow-1 pointer justify-end position-relative' style={{top:-20, right:8}} onClick={() => goToPkgList('month')}>
                                <div className='Rubik-Regular text_thmdark fs_14' >View All</div>
                                <i className="fa-solid fa-chevron-right ms-2 fs_14 bg-gradient-thm hw_20px rd_50 text-white d-flex align-items-center justify-content-center"></i>
                            </div>
                        </div>
                        <div className='d-flex align-items-center pb-3 scrollable-container scrollable'>
                            {ordersMonth.map((month, index) => (
                                <div
                                    key={index}
                                    onClick={() => { handleCallback(month, 'month'); setSelected(month); setMothClicked(true); }}
                                    className={`mt-2 pointer fs_13 p-1 rounded-5 mx-1 px-3 ${month == selected ? 'Source_Medium current-month text-white bg_thm' : 'Sansation_Regular text_gray2 border_white brc-secondary'}`}
                                >
                                    {month}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* <div className='w-100'>
                    <Loader type={'pkg'} mt={'mt-5'} />
                </div> */}

                {isLoading1 ? (
                    <Loader type={'pkg'} mt={'mt-4-5'} />
                ) : (
                    pkgList2 && pkgList2.length > 0 ? (
                        <>

                            {/* filter list */}
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                responsive={responsive_8}
                                ssr={true}
                                autoPlay
                                autoPlaySpeed={2500}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                containerClass="carousel-container"
                                itemClass="carousel-item1 "
                                customLeftArrow={
                                    <CustomLeftArrow
                                        direction="left"
                                    />
                                }
                                customRightArrow={
                                    <CustomLeftArrow
                                        direction="right"
                                    />
                                }
                                removeArrowOnDeviceType={["desktop1", "desktop", "tablet", "mobile", "mobilex"]}
                                dotListClass="custom-dot-list-style"
                            >
                                {pkgList2 && pkgList2?.slice(0, 4).map((item, index) => {
                                    const lowPrice = item.discountPrice
                                    const final = (lowPrice == null || lowPrice == 0) ? item.price : lowPrice
                                    const vendor = item?.vendorId
                                    return (
                                        <div key={item._id} className='hover-highlight-pkg h_270px pkg-btn-hover border_white br-2 mt-3 mb-4 rd_6 mx-2-5 pointer shadow-slowclean' style={{ backgroundColor: '#fff' }}>
                                            <Link target='_blank' to={`/package/${item?.slug}/${item.id}`} state={{ type_id: item._id, type: 'Package', cId: state?.cID }} className='text-decoration-none hover-me'>
                                                <img alt='img' src={`${img_base_url + item?.image}`} className='h_150px w-100 rd-tl-tr-4px' />

                                                <div className='p-2-5'>
                                                    <div className='Rubik-SemiBold fs-14px text_gray text_dark_medium m-0 p-0 mini-line-1'>{item?.name}</div>
                                                    <p className='Rubik-Regular fs_11 text_gray2 mini-line-1'>By {vendor?.business_name}</p>

                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <div className='Rubik-Regular fs_14 text-muted'><strong className='text_dark_medium fs_15'>{item?.currency?.code}{final}</strong>/Person</div>
                                                            {lowPrice != null && lowPrice > 0 && (
                                                                <div className='Rubik-Regular fs_12 TxtCut text-secondary'>{item?.currency?.code}{item.price}</div>
                                                            )}
                                                        </div>
                                                        <div className='book-now border_thmdark rounded-5 fs_13 h_30px Rubik-Light d-flex align-items-center px-2-5 text_thm'>View Details</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </>
                    ) : (
                        <div className='h-100 w-100 d-flex justify-content-center align-items-center flex-column'>
                            <img src='/images/not_data.webp' className='img-fluid h_130px w_200px' />
                            <div className='Rubik-Regular text_gray2 mt-2'>No month packages available</div>
                        </div>
                    )
                )
                }



                {/* ===========================Tags section================================ */}
            </div>

            <div className='w-100 mt-1 d-flex align-items-center justify-content-center'>
                <div className='w_95 mt-4'>
                    {tagsList3 && tagsList3?.locations?.length > 0 &&
                        <div className='bg-white rd_6 p-3  mb-3'>
                            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Locations in {cName}</h2>

                            {tagsList3 ? (
                                <div className='d-flex flex-wrap gap-2'>
                                    {tagsList3?.locations?.slice(0, showTags).map((it, index) => {
                                        const slug = it.name.replace(/ /g, '-');

                                        return (
                                            <Link to={`/location/${slug}/${it.id}`} target='_blank' key={it._id} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                                Top places to visit in {it.name}

                                            </Link>
                                        )
                                    })}
                                    {showTags == 30 && <div onClick={() => setShowTags(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                        See All
                                        <i class="fa-solid fa-angles-right ml-5px"></i>
                                    </div>}
                                </div>
                            ) : (
                                <Spin className='ms-2 mb-2 thm-spin' />
                            )}
                        </div>
                    }


                    {tagsList3 && tagsList3?.states?.length > 0 &&
                        <div className='bg-white rd_6 p-3 mb-3'>
                            <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular States in {cName}</h2>

                            {tagsList3 ? (
                                <div className='d-flex flex-wrap gap-2'>
                                    {tagsList3 && tagsList3?.states?.slice(0, showTags1).map((it, index) => {
                                        const slug = it.name.replace(/ /g, '-')
                                        return (
                                            <Link to={`/state/${slug}/${it.id}`} target='_blank' key={it._id} className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                                Top places to visit in {it.name}
                                            </Link>
                                        )
                                    })}
                                    {showTags1 == 30 && <div onClick={() => setShowTags1(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                        See All
                                        <i class="fa-solid fa-angles-right ml-5px"></i>
                                    </div>}
                                </div>
                            ) : (
                                <Spin className='ms-2 mb-2 thm-spin' />
                            )}
                        </div>
                    }

                    <div className='bg-white rd_6 p-3 mb-5'>
                        <h2 className="mb-2 Rubik-SemiBold fs_16 mb-4">Popular Countries</h2>
                        {tagsList3 ? (
                            <div className='d-flex flex-wrap gap-2'>
                                {tagsList3 && tagsList3?.countries?.slice(0, showTags2).map((it, index) => {
                                    const slug = it.name.replace(/ /g, '-')
                                    return (
                                        <Link key={it._id} to={`/country/${slug}/${it.id}`} target='_blank' className='hover-bg-lightblue pointer border border-light-subtle px-2 rd_6 Source_Regular text-muted fs_12 p-1 bg-light-subtle'>
                                            Top places to visit in {it.name}
                                        </Link>
                                    )
                                })}
                                {showTags2 == 30 && <div onClick={() => setShowTags2(120)} className='pointer px-2 rd_6 Source_Regular text_thm fs_12 p-1 pt-5px'>
                                        See All
                                        <i class="fa-solid fa-angles-right ml-5px"></i>
                                    </div>}
                            </div>
                        ) : (
                            <Spin className='ms-2 mb-2 thm-spin' />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PkgIndex