import React, { useCallback, useContext, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import animationData from '../CSS/success.json';
import congratulation from '../CSS/congratulation.json';
import { MemContext } from '../ContextRedux/Context';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';


const ShowWlcmModal = () => {
    const { isLoggingIn, isRegistering, toggleLogin, toggleRegister } = useContext(MemContext);
    const user = useSelector(state => state.data.info.user)

    return (
        <>
            <Modal
                title=""
                className='Source_Medium text_gray w-xs-auto mx-xs-4 '
                styles={{
                    body: {
                        padding: '0 10px', height: 'auto'
                    },
                }}
                width={'30%'}
                footer={null}
                open={isLoggingIn ? isLoggingIn : isRegistering}
                onOk={isLoggingIn ? toggleLogin : toggleRegister}
                onCancel={isLoggingIn ? toggleLogin : toggleRegister}
            >
                <div className="h-250px d-align-justify-center flex-column ">
                    <Lottie animationData={animationData} loop={true} autoplay={true} className='h-60p' />

                    {isLoggingIn ?
                        <div className='Inter-Regular fs-16px text-center mt-20px'>It’s great to see you again, <span className='Inter-SemiBold'>{user && user.first_name || ''}</span>! Let’s get started.</div>
                        :
                        <div className='Inter-Medium fs-16px text-center mt-20px'>Congratulations! Your account is ready—let’s make great things happen.</div>
                    }
                </div>
            </Modal>
            {(isLoggingIn || isRegistering) &&
                <div className="position-absolute w-auto h-80p z-5 top-0 z-index-top d-flex justify-content-center align-items-start" style={{ left: '38%' }}>
                    <Lottie animationData={congratulation} loop={true} autoplay={true} className='h-100p w-100p' />
                </div>
            }
        </>
    )
}

export default ShowWlcmModal