import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SampleNextArrow, SamplePrevArrow } from './CustomArrow';
import { useCountryGet } from './CommonFs';
const key = process.env.REACT_APP_KEY;

const CarouselMe_D = ({ countries, img_base_url, callback, digitId, c_Name }) => {
    const sliderRef2 = useRef(null);
    const [country, setCountry] = useState('');
    const [countryId, setCountryId] = useState('');
    const { country1 } = useCountryGet();


    const filterId = countries.filter(item => item.name === country1)?.[0]
    const selectedValue = (country == '' || country == undefined) ? filterId?._id : country
    const selectedValueID = (countryId == '' || countryId == undefined) ? filterId?.id : countryId


    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 10, // Default for large screens
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: false, // Ensure items are aligned to the start
        adaptiveHeight: true, // Adjust height dynamically
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 8,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                },
            },
        ]
    };

    useEffect(() => {
        if (selectedValue !== undefined) {
            getList()
        }

    }, [selectedValue])

    const getList = async () => {
        // await localStorage.setItem('pkgSelectedCountry', JSON.stringify(selectedValue))
        setCountry(selectedValue)
        if (!country) {
            callback(selectedValue, 'cID')
            digitId(selectedValueID, 'digit')
            c_Name(filterId?.name)
        }
        // const filter = countries.filter((it) => it._id === id)
    }

    const handleGetList = async (id, name, idDigit, index) => {
        await localStorage.setItem('pkgSelectedCountry', JSON.stringify(id))

        setCountry(id)
        digitId(idDigit, 'digit')
        callback(id, 'cID')
        c_Name(name)
    };


    const defaultUserCountry = countries.filter(country => country._id === filterId?._id)
    const other = countries.filter(country => country._id !== filterId?._id);
    const countryList = [...defaultUserCountry, ...other]

    return (
        <div className="slider-container" style={{ width: '100%', overflow: 'hidden' }}>
            {countryList && countryList.length > 0 ? (
                <Slider ref={sliderRef2} {...settings}>
                    {countryList && countryList.map((item, index) => {
                        return (
                            <div key={item.name} onClick={() => handleGetList(item._id, item.name, item.id, index)} className={`d-flex flex-column align-items-center justify-content-center pointer`}>
                                <div className={`w-100 rd_10 hw_50px d-flex align-items-center justify-content-center ${selectedValue === item._id ? 'bg-white shadow-slowclean' : ''}`}>
                                    <img src={`${img_base_url + item.icon}`} className='h_70 w_70 rd_6' alt={item.name} />
                                </div>
                                <p className={`m-0 mt-2 text_gray fs_14 Source_Regular text-center ${selectedValue === item._id ? 'text_thmdark Source_Medium heading-underline underline_thmdark' : ''}`}>{item.name}</p>
                            </div>
                        );
                    })}
                </Slider>
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default CarouselMe_D;

